import React from "react";
import {
  Comment,
  Conditions,
  Currency,
  DuplicationRule,
  FeeCode,
  FeeName,
  Field,
  Leg,
  RangeSelection,
  RateBasis,
  RouteRestriction,
} from "src/libs/Fields";
import { Range } from "src/types";
import { CellEntity } from "../../CellEntity";
import { Entity } from "../../Entity";
import { Currency as CurrencyHeader } from "../../Globals";
import { TableEntity } from "../../TableEntity";
import { AddOnTop } from "src/libs/Fields/AddOnTop";
import { Transshipment } from "../../Tables";

export abstract class OceanFee extends CellEntity {
  protected add_on_top: AddOnTop;
  protected fee_code: FeeCode;
  protected fee_name: FeeName;
  protected currency: Currency;
  protected rate_basis: RateBasis;
  protected comment: Comment;
  protected Leg: Leg;
  protected duplication_rule: DuplicationRule;
  protected conditions: Conditions;
  protected route_restrictions: RouteRestriction;

  constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    parentTable: TableEntity,
    override?: boolean
  ) {
    super(
      id,
      range,
      "OCEAN Fee",
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status,
      false,
      parentTable,
      override
    );
    this.fee_code = new FeeCode("fee_code", true, true);
    this.fee_name = new FeeName("fee_name", true, true);
    this.currency = new Currency("currency", false, "Fee currency", true);
    const table_currency = this.parentTable
      ?.getHeaders()
      .find((field) => field instanceof CurrencyHeader);
    if (table_currency) {
      this.currency.setValue(table_currency.getFields()[0].getValue());
    }
    this.rate_basis = new RateBasis("rate_basis", false);
    this.rate_basis.setValue(this.rate_basis.options[0]);
    this.comment = new Comment("comment", true, true);
    this.Leg = new Leg("Leg", true, true);
    this.duplication_rule = new DuplicationRule("duplication_rule", false);
    this.conditions = new Conditions("conditions", false);
    this.route_restrictions = new RouteRestriction("route_restrictions", false);
    this.add_on_top = new AddOnTop("add_on_top", false);
  }

  public getFields(): Field[] {
    const res: Field[] = [
      this.fee_code,
      this.fee_name,
      this.currency,
      this.rate_basis,
      this.comment,
      this.Leg,
      this.duplication_rule,
      this.conditions,
      this.route_restrictions,
    ];
    if (this.parentTable instanceof Transshipment) {
      res.splice(8, 0, this.add_on_top);
    }
    return res;
  }

  public getNameField(control: any): React.ReactNode {
    return null;
  }

  public toJson(): any {
    return {
      ...super.toJson(),
      feeCode:
        this.fee_code.getValue() instanceof RangeSelection
          ? (this.fee_code.getValue() as RangeSelection).coordinate
          : this.fee_code.getValue(),
      feeName:
        this.fee_name.getValue() instanceof RangeSelection
          ? (this.fee_name.getValue() as RangeSelection).coordinate
          : this.fee_name.getValue(),
      feeId: this.name,
      currency:
        this.currency.getValue() instanceof RangeSelection
          ? (this.currency.getValue() as RangeSelection).coordinate
          : this.currency.getValue(),
      ...this.rate_basis.getValue()?.value,
      feeType: this.rate_basis.getValue()?.feeType,
      comment:
        this.comment.getValue() instanceof RangeSelection
          ? (this.comment.getValue() as RangeSelection).coordinate
          : this.comment.getValue(),
      feePayment:
        this.Leg.getValue(true) instanceof RangeSelection
          ? (this.Leg.getValue(true) as RangeSelection).coordinate
          : this.Leg.getValue(true),
      duplicationRule: this.duplication_rule.getValue(true),
      conditions: this.conditions.getValue(true),
      addOnTop: this.add_on_top.getValue(),
      routeRestrictions: this.route_restrictions.getValue(),
      metadata: {
        ...super.toJson().metadata,
        rateBasis: this.rate_basis.getValue(),
      },
      extractFromRange: {
        feeCode:
          this.fee_code.getValue() instanceof RangeSelection
            ? (this.fee_code.getValue() as RangeSelection).toJson()
            : undefined,
        feeName:
          this.fee_name.getValue() instanceof RangeSelection
            ? (this.fee_name.getValue() as RangeSelection).toJson()
            : undefined,
        currency:
          this.currency.getValue() instanceof RangeSelection
            ? (this.currency.getValue() as RangeSelection).toJson()
            : undefined,
        comment:
          this.comment.getValue() instanceof RangeSelection
            ? (this.comment.getValue() as RangeSelection).toJson()
            : undefined,
        feePayment:
          this.Leg.getValue() instanceof RangeSelection
            ? (this.Leg.getValue() as RangeSelection).toJson()
            : undefined,
      },
    };
  }

  public fromJson(json: any, map: Map<string, Entity>): void {
    super.fromJson(json, map);
    this.fee_code.setValue(json.feeCode);
    this.fee_name.setValue(json.feeName);
    this.currency.setValue(json.currency);
    this.rate_basis.setValue(json.rateBasis ?? json.metadata.rateBasis, true);
    this.comment.setValue(json.comment);
    this.Leg.setValue(json.feePayment ?? json.leg, true);
    this.duplication_rule.setValue(json.duplicationRule, true);
    this.conditions.setValue(json.conditions, true);
    this.add_on_top.setValue(json.addOnTop);
    this.route_restrictions.setValue(json.routeRestrictions, true);
    if (json.extractFromRange?.feeCode) {
      this.fee_code.setValue(
        RangeSelection.fromJson(json.extractFromRange?.feeCode)
      );
    } else {
      this.fee_code.setValue(json.feeCode);
    }

    if (json.extractFromRange?.feeName) {
      this.fee_name.setValue(
        RangeSelection.fromJson(json.extractFromRange?.feeName)
      );
    } else {
      this.fee_name.setValue(json.feeName);
    }

    if (json.extractFromRange?.currency) {
      this.currency.setValue(
        RangeSelection.fromJson(json.extractFromRange?.currency)
      );
    } else {
      this.currency.setValue(json.currency);
    }

    if (json.extractFromRange?.comment) {
      this.comment.setValue(
        RangeSelection.fromJson(json.extractFromRange?.comment)
      );
    } else {
      this.comment.setValue(json.comment);
    }

    if (json.extractFromRange?.feePayment) {
      this.Leg.setValue(
        RangeSelection.fromJson(json.extractFromRange?.feePayment)
      );
    } else {
      this.Leg.setValue(json.feePayment ?? json.leg, true);
    }
  }
}
