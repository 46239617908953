import FieldIconSelect from "src/components/FieldIconSelect";
import { SelectInput } from "src/components/Inputs";
import { Field, RangeSelection } from "./Field";

export class Currency extends Field {
  value: string | null | RangeSelection;
  private rangeSelection: boolean;

  constructor(
    fieldName: string,
    required: boolean = false,
    label: string = "Currency",
    rangeSelection: boolean = false
  ) {
    super(fieldName, label, required);
    this.value = null;
    this.rangeSelection = rangeSelection;
  }

  public getValue(): string | null | RangeSelection {
    return this.value;
  }

  public setValue(value: string | null | RangeSelection): void {
    if (
      this.value instanceof RangeSelection &&
      value === this.value.coordinate
    ) {
      return; // No change
    }
    this.value = value;
  }

  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    const options = [
      "BOV",
      "UZS",
      "CHF",
      "MRO",
      "XBB",
      "PGK",
      "ROL",
      "DOP",
      "WST",
      "ERN",
      "GHP",
      "BSD",
      "PEH",
      "XCD",
      "LSL",
      "ZWN",
      "LKR",
      "BYN",
      "KMF",
      "SLL",
      "GIP",
      "NGN",
      "GNE",
      "MGF",
      "UYU",
      "CDF",
      "AZM",
      "SDD",
      "RWF",
      "PES",
      "TND",
      "ZWC",
      "XPT",
      "HRK",
      "PLN",
      "HNL",
      "TJR",
      "BEC",
      "ZWR",
      "PYG",
      "BOB",
      "XEU",
      "UAH",
      "USN",
      "CHW",
      "RON",
      "CSK",
      "SOS",
      "RUR",
      "STN",
      "MUR",
      "YUD",
      "BRN",
      "RUB",
      "MAD",
      "DJF",
      "SGD",
      "XTS",
      "STD",
      "MRU",
      "BAM",
      "GEK",
      "UAK",
      "GMD",
      "VND",
      "ROK",
      "XXX",
      "BGK",
      "XPF",
      "AFA",
      "KPW",
      "TRL",
      "IQD",
      "MLF",
      "XAG",
      "CYP",
      "RSD",
      "GQE",
      "ILP",
      "VEF",
      "BRR",
      "BGJ",
      "MVR",
      "AUD",
      "NOK",
      "KZT",
      "ALL",
      "LAJ",
      "MZM",
      "SAR",
      "YDD",
      "XFU",
      "FRF",
      "ESB",
      "ILS",
      "JMD",
      "GYD",
      "GBP",
      "MXV",
      "LVL",
      "GNS",
      "XBD",
      "CLP",
      "ALK",
      "TRY",
      "TMM",
      "KHR",
      "XBC",
      "BMD",
      "BOP",
      "MMK",
      "LRD",
      "AFN",
      "BRC",
      "USS",
      "YUN",
      "BND",
      "PEI",
      "DKK",
      "GWP",
      "CVE",
      "MTP",
      "ZAR",
      "SEK",
      "LBP",
      "BZD",
      "THB",
      "BRB",
      "CHE",
      "BAD",
      "CRC",
      "ARS",
      "CLF",
      "PEN",
      "BTN",
      "LUC",
      "GRD",
      "ITL",
      "UYN",
      "ARP",
      "XDR",
      "NPR",
      "HUF",
      "PHP",
      "UGW",
      "LVR",
      "MGA",
      "VES",
      "FJD",
      "BIF",
      "AOA",
      "EUR",
      "XBA",
      "CUC",
      "ADP",
      "BGN",
      "OMR",
      "IDR",
      "MKD",
      "KGS",
      "XRE",
      "COU",
      "HKD",
      "AED",
      "XAU",
      "ARA",
      "ILR",
      "SBD",
      "GNF",
      "ESA",
      "EEK",
      "EGP",
      "DDM",
      "UYW",
      "BYB",
      "PAB",
      "BHD",
      "AMD",
      "TPE",
      "LUL",
      "GEL",
      "LSM",
      "TTD",
      "CZK",
      "ISK",
      "LTL",
      "ECV",
      "BRL",
      "JOD",
      "ATS",
      "SZL",
      "ECS",
      "SVC",
      "CSD",
      "SRD",
      "XFO",
      "VNC",
      "XPD",
      "NIO",
      "PTE",
      "ZMW",
      "KWD",
      "XSU",
      "SHP",
      "MNT",
      "BRE",
      "BEF",
      "BUK",
      "XAF",
      "AYM",
      "ETB",
      "MYR",
      "BGL",
      "COP",
      "BBD",
      "LYD",
      "ISJ",
      "TWD",
      "TMT",
      "VUV",
      "ANG",
      "GHS",
      "MTL",
      "ZRZ",
      "AZN",
      "TOP",
      "AON",
      "AOR",
      "GTQ",
      "KES",
      "XOF",
      "INR",
      "MXP",
      "PLZ",
      "JPY",
      "MWK",
      "ARY",
      "SDP",
      "ESP",
      "NLG",
      "UGS",
      "MXN",
      "BWP",
      "IEP",
      "ZRN",
      "SKK",
      "NZD",
      "BDT",
      "MOP",
      "VEB",
      "SCR",
      "IRR",
      "ZMK",
      "DEM",
      "FKP",
      "ZAL",
      "ZWD",
      "SRG",
      "CNY",
      "LTT",
      "USD",
      "GHC",
      "BEL",
    ];

    if (this.value instanceof RangeSelection) {
      options.push(this.value.coordinate);
    }
    return (
      <SelectInput
        label={this.label}
        fieldName={this.fieldName}
        options={options}
        control={control}
        rules={{ required: this.required && "Currency is mandatory!" }}
        InputProps={{
          endAdornment: this.rangeSelection ? (
            <FieldIconSelect field={this} />
          ) : undefined,
        }}
      />
    );
  }
}
