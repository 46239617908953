import { Range } from "src/types";
import {
  Currency,
  FeeCode,
  FeeName,
  Field,
  Value,
  RateBasis,
  Comment,
  Leg,
  DuplicationRule,
  OptionalFee,
  AdditionalFee,
  Hazardous,
  Conditions,
  RouteRestriction,
  Validity,
  RangeSelection,
} from "src/libs/Fields";
import { CellEntity } from "../../CellEntity";
import { TableEntity } from "../../TableEntity";
import React from "react";
import { TextInput } from "src/components/Inputs";
import { Entity } from "../../Entity";

export abstract class GeneralFee extends CellEntity {
  private fee_code: FeeCode;
  private fee_name: FeeName;
  private value: Value;
  private currency: Currency;
  private rate_basis: RateBasis;
  private comment: Comment;
  private leg: Leg;
  private duplication_rule: DuplicationRule;
  private optional_fee: OptionalFee;
  private additional_fee: AdditionalFee;
  private hazardous: Hazardous;
  private conditions: Conditions;
  private validity_start: Validity;
  private validity_end: Validity;
  private route_restrictions: RouteRestriction;

  constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    global: boolean = false,
    parentTable?: TableEntity,
    override?: boolean
  ) {
    super(
      id,
      range,
      "General Fee",
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status,
      global,
      parentTable,
      override
    );
    this.fee_code = new FeeCode("fee_code", true, true);
    this.fee_name = new FeeName("fee_name", true, true);
    this.value = new Value("value", true);
    this.currency = new Currency("currency", false, "Fee currency", true);
    this.rate_basis = new RateBasis("rate_basis", false);
    this.comment = new Comment("comment", true, true);
    this.leg = new Leg("leg", true, true);
    this.duplication_rule = new DuplicationRule("duplication_rule", false);
    this.optional_fee = new OptionalFee("optional_fee", false);
    this.additional_fee = new AdditionalFee("additional_fee", false);
    this.hazardous = new Hazardous("hazardous", false);
    this.conditions = new Conditions("conditions", false);
    this.validity_start = new Validity(
      "validity_start",
      "Validity Start",
      false
    );
    this.validity_end = new Validity("validity_end", "Validity End", false);
    this.route_restrictions = new RouteRestriction("route_restrictions", false);
  }

  public getFields(): Field[] {
    return [
      this.fee_code,
      this.fee_name,
      this.value,
      this.currency,
      this.rate_basis,
      this.comment,
      this.leg,
      this.duplication_rule,
      this.optional_fee,
      this.additional_fee,
      this.hazardous,
      this.conditions,
      this.validity_start,
      this.validity_end,
      this.route_restrictions,
    ];
  }

  public getNameField(control: any): React.ReactNode {
    return (
      <TextInput
        fieldName="name"
        label="Fee Id"
        rules={{ required: "Fee Id is mandatory!" }}
        control={control}
      />
    );
  }

  // public getName(): string {
  //   let name = this.fee_name.getValue();
  //   if (name instanceof RangeSelection) {
  //     name = name.coordinate;
  //   }
  //   return `${name} ${this.coordinate}`;
  // }

  public toJson(): any {
    return {
      ...super.toJson(),
      feeCode:
        this.fee_code.getValue() instanceof RangeSelection
          ? (this.fee_code.getValue() as RangeSelection).coordinate
          : this.fee_code.getValue(),
      feeName:
        this.fee_name.getValue() instanceof RangeSelection
          ? (this.fee_name.getValue() as RangeSelection).coordinate
          : this.fee_name.getValue(),
      feeId: this.name,
      value: [this.value.getValue() ?? 0],
      currency:
        this.currency.getValue() instanceof RangeSelection
          ? (this.currency.getValue() as RangeSelection).coordinate
          : this.currency.getValue(),
      ...this.rate_basis.getValue()?.value,
      feeType: this.rate_basis.getValue()?.feeType,
      comment:
        this.comment.getValue() instanceof RangeSelection
          ? (this.comment.getValue() as RangeSelection).coordinate
          : this.comment.getValue(),
      feePayment:
        this.leg.getValue(true) instanceof RangeSelection
          ? (this.leg.getValue(true) as RangeSelection).coordinate
          : this.leg.getValue(true),
      duplicationRule: this.duplication_rule.getValue(true),
      optionalFee: this.optional_fee.getValue(),
      additionalFee: this.additional_fee.getValue(),
      hazardous: this.hazardous.getValue(),
      conditions: this.conditions.getValue(true),
      validityStart: this.validity_start.getValue(),
      validityEnd: this.validity_end.getValue(),
      routeRestrictions: this.route_restrictions.getValue(),
      metadata: {
        ...super.toJson().metadata,
        rateBasis: this.rate_basis.getValue(),
      },
      extractFromRange: {
        feeCode:
          this.fee_code.getValue() instanceof RangeSelection
            ? (this.fee_code.getValue() as RangeSelection).toJson()
            : undefined,
        feeName:
          this.fee_name.getValue() instanceof RangeSelection
            ? (this.fee_name.getValue() as RangeSelection).toJson()
            : undefined,
        currency:
          this.currency.getValue() instanceof RangeSelection
            ? (this.currency.getValue() as RangeSelection).toJson()
            : undefined,
        comment:
          this.comment.getValue() instanceof RangeSelection
            ? (this.comment.getValue() as RangeSelection).toJson()
            : undefined,
        feePayment:
          this.leg.getValue() instanceof RangeSelection
            ? (this.leg.getValue() as RangeSelection).toJson()
            : undefined,
      },
    };
  }

  public fromJson(json: any, map: Map<string, Entity>): void {
    super.fromJson(json, map);
    this.value.setValue(json.value[0]);
    this.rate_basis.setValue(json.rateBasis ?? json.metadata.rateBasis, true);
    this.duplication_rule.setValue(json.duplicationRule, true);
    this.optional_fee.setValue(json.optionalFee);
    this.additional_fee.setValue(json.additionalFee);
    this.hazardous.setValue(json.hazardous);
    this.conditions.setValue(json.conditions, true);
    this.validity_start.setValue(json.validityStart);
    this.validity_end.setValue(json.validityEnd);
    this.route_restrictions.setValue(json.routeRestrictions, true);

    if (json.extractFromRange?.feeCode) {
      this.fee_code.setValue(
        RangeSelection.fromJson(json.extractFromRange?.feeCode)
      );
    } else {
      this.fee_code.setValue(json.feeCode);
    }

    if (json.extractFromRange?.feeName) {
      this.fee_name.setValue(
        RangeSelection.fromJson(json.extractFromRange?.feeName)
      );
    } else {
      this.fee_name.setValue(json.feeName);
    }

    if (json.extractFromRange?.currency) {
      this.currency.setValue(
        RangeSelection.fromJson(json.extractFromRange?.currency)
      );
    } else {
      this.currency.setValue(json.currency);
    }

    if (json.extractFromRange?.comment) {
      this.comment.setValue(
        RangeSelection.fromJson(json.extractFromRange?.comment)
      );
    } else {
      this.comment.setValue(json.comment);
    }

    if (json.extractFromRange?.feePayment) {
      this.leg.setValue(
        RangeSelection.fromJson(json.extractFromRange?.feePayment)
      );
    } else {
      this.leg.setValue(json.feePayment ?? json.leg, true);
    }
  }
}
