import { Range } from "src/types";
import { Port, Field, Seperator, BiDirectional } from "../../Fields";
import { CellEntity } from "../CellEntity";
import { TableEntity } from "../TableEntity";

export class POL extends CellEntity {
  private port: Port;
  private seperator: Seperator;
  private bidirectional: BiDirectional;

  constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    global: boolean = false,
    parentTable?: TableEntity,
    override?: boolean,
    type = "POL"
  ) {
    super(
      id,
      range,
      type,
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status,
      global,
      parentTable,
      override
    );
    this.port = new Port("port", false);
    this.seperator = new Seperator("seperator", false);
    this.bidirectional = new BiDirectional("bidirectional", false);
  }

  public getFields(): Field[] {
    return [this.port, this.seperator, this.bidirectional];
  }

  public toJson(): any {
    const ports = this.port.getValue().map((p: any) => p.value);
    return {
      ...super.toJson(),
      value: ports,
      scope: ports?.length > 0 ? "global" : "local",
      seperator: this.seperator.getValue(),
      bidirectional: this.bidirectional.getValue(),
      metadata: {
        ...super.toJson().metadata,
        ports: this.port.getValue(),
      },
    };
  }

  public getNameField(control: any): React.ReactNode {
    return this.global ? super.getNameField(control) : null;
  }

  public fromJson(json: any, map: Map<string, CellEntity>): void {
    super.fromJson(json, map);
    this.port.setValue(json.metadata.ports);
    this.seperator.setValue(json.seperator);
    this.bidirectional.setValue(json.bidirectional);
  }
}
